<template>
  <v-row justify="center">
    <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
      <v-form @submit.prevent="handleSubmit($emit('submit'))">
        <v-dialog
          height="400px"
          scrollable
          v-model="dialogState"
          :max-width="width"
          @click:outside="$emit('close')"
        >
          <v-card no-body :disabled="load" :loading="load">
            <v-card-title>
              <span>
                {{ title }}
              </span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text class="">
              <!-- <b-overlay :show="load" rounded="sm" width="100%">
                <v-container> -->
              <slot name="content"></slot>
              <!-- </v-container>
              </b-overlay> -->
            </v-card-text>
            <v-divider></v-divider>

            <v-card-actions class="justify-content-end p-5">
              <v-btn v-if="!readonly" color="success" :disabled="invalid" @click="$emit('submit')">
                {{ $t("save") }}
              </v-btn>
              <v-btn color="error" @click="$emit('close')">
                {{ $t("close") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-form>
    </validation-observer>
  </v-row>
</template>

<script>
export default {
  props: {
    title: String,
    dialog: Boolean,
    load: Boolean,
    width: {
      default: "800px",
      type: String,
    },
    readonly: Boolean,
  },
  computed: {
    dialogState: {
      get() {
        return this.dialog;
      },
      set() {
        this.$emit("update:dialog", false);
      },
    },
  },
  methods: {
    reset() {
      if (this.$refs.observer) {
        this.$refs.observer.reset();
      }
    },
  },
};
</script>

<style>
</style>