<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <v-btn :class="_class" @click="$listeners.click" :color="color" dark v-bind="attrs" v-on="on">
        <slot></slot>
      </v-btn>
    </template>
    <span>{{description}}</span>
  </v-tooltip>
</template>

<script>
export default {
  props: { description: null, color: null , _class:null },
  name:"base-btn"
};
</script>

<style>
</style>